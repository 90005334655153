import React from 'react';

import PropTypes from 'prop-types';

export default function CaretDown({
  color = 'currentColor',
  size = '1em',
  ...rest
}) {
  return (
    <svg
      viewBox="0 0 36 32"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      height={size}
      width={size}
      {...rest}
    >
      <path
        d="M30.1733 19.8267L20 30L15 31L16 26L26.1733 15.8267C26.4351 15.5645 26.7461 15.3564 27.0883 15.2145C27.4306 15.0726 27.7975 14.9995 28.168 14.9995C28.5385 14.9995 28.9054 15.0726 29.2477 15.2145C29.5899 15.3564 29.9009 15.5645 30.1627 15.8267L30.1733 15.8373C30.4355 16.0991 30.6436 16.4101 30.7855 16.7523C30.9274 17.0946 31.0005 17.4615 31.0005 17.832C31.0005 18.2025 30.9274 18.5694 30.7855 18.9117C30.6436 19.2539 30.4355 19.5649 30.1733 19.8267V19.8267Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 21H3C2.46957 21 1.96086 20.7893 1.58579 20.4142C1.21071 20.0391 1 19.5304 1 19V3C1 2.46957 1.21071 1.96086 1.58579 1.58579C1.96086 1.21071 2.46957 1 3 1H27C27.5304 1 28.0391 1.21071 28.4142 1.58579C28.7893 1.96086 29 2.46957 29 3V11"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.548 1.73334L17.6893 10.0853C16.9183 10.6785 15.9728 11.0001 15 11.0001C14.0272 11.0001 13.0817 10.6785 12.3107 10.0853L1.452 1.73334"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

CaretDown.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
