import React from 'react';

import PropTypes from 'prop-types';

export default function CheckCircle({
  color = 'currentColor',
  size = '1em',
  ...rest
}) {
  return (
    <svg
      viewBox="0 0 36 32"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      height={size}
      width={size}
      {...rest}
    >
      <path
        d="M8 17.6307L11.2667 22.2667C11.3906 22.4518 11.5569 22.6046 11.7518 22.7124C11.9467 22.8202 12.1645 22.88 12.3872 22.8866C12.6098 22.8933 12.8308 22.8467 13.0318 22.7507C13.2328 22.6547 13.4079 22.512 13.5427 22.3347L24 9.104"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 15.9987C1 19.9769 2.58035 23.7922 5.3934 26.6053C8.20644 29.4183 12.0218 30.9987 16 30.9987C19.9782 30.9987 23.7936 29.4183 26.6066 26.6053C29.4196 23.7922 31 19.9769 31 15.9987C31 12.0204 29.4196 8.20511 26.6066 5.39206C23.7936 2.57902 19.9782 0.998665 16 0.998665C12.0218 0.998665 8.20644 2.57902 5.3934 5.39206C2.58035 8.20511 1 12.0204 1 15.9987V15.9987Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

CheckCircle.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
