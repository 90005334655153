import React from 'react';

import PropTypes from 'prop-types';

export default function Receipt({
  color = 'currentColor',
  size = '1em',
  ...rest
}) {
  return (
    <svg
      viewBox="0 0 36 32"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      height={size}
      width={size}
      {...rest}
    >
      <path
        d="M28 31V3C28 2.46957 27.7893 1.96086 27.4142 1.58579C27.0391 1.21071 26.5304 1 26 1H6C5.46957 1 4.96086 1.21071 4.58579 1.58579C4.21071 1.96086 4 2.46957 4 3V31L8 27L12 31L16 27L20 31L24 27L28 31Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 8H16"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 14H16"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 20H16"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.5 7C23.5989 7 23.6956 7.02932 23.7778 7.08427C23.86 7.13921 23.9241 7.2173 23.9619 7.30866C23.9998 7.40002 24.0097 7.50055 23.9904 7.59754C23.9711 7.69454 23.9235 7.78363 23.8536 7.85355C23.7836 7.92348 23.6945 7.9711 23.5975 7.99039C23.5006 8.00969 23.4 7.99978 23.3087 7.96194C23.2173 7.9241 23.1392 7.86001 23.0843 7.77778C23.0293 7.69556 23 7.59889 23 7.5C23 7.36739 23.0527 7.24022 23.1464 7.14645C23.2402 7.05268 23.3674 7 23.5 7Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.5 13C23.5989 13 23.6956 13.0293 23.7778 13.0843C23.86 13.1392 23.9241 13.2173 23.9619 13.3087C23.9998 13.4 24.0097 13.5006 23.9904 13.5975C23.9711 13.6945 23.9235 13.7836 23.8536 13.8536C23.7836 13.9235 23.6945 13.9711 23.5975 13.9904C23.5006 14.0097 23.4 13.9998 23.3087 13.9619C23.2173 13.9241 23.1392 13.86 23.0843 13.7778C23.0293 13.6956 23 13.5989 23 13.5C23 13.3674 23.0527 13.2402 23.1464 13.1464C23.2402 13.0527 23.3674 13 23.5 13Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.5 19C23.5989 19 23.6956 19.0293 23.7778 19.0843C23.86 19.1392 23.9241 19.2173 23.9619 19.3087C23.9998 19.4 24.0097 19.5006 23.9904 19.5975C23.9711 19.6945 23.9235 19.7836 23.8536 19.8536C23.7836 19.9235 23.6945 19.9711 23.5975 19.9904C23.5006 20.0097 23.4 19.9998 23.3087 19.9619C23.2173 19.9241 23.1392 19.86 23.0843 19.7778C23.0293 19.6956 23 19.5989 23 19.5C23 19.3674 23.0527 19.2402 23.1464 19.1464C23.2402 19.0527 23.3674 19 23.5 19Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

Receipt.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
