import React from 'react';

import PropTypes from 'prop-types';

export default function Camera({
  color = 'currentColor',
  size = '1em',
  ...rest
}) {
  return (
    <svg
      viewBox="0 0 36 32"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      height={size}
      width={size}
      {...rest}
    >
      <path
        d="M20.2687 4C20.475 4 20.6625 4.13125 20.7375 4.325L22.1187 8H29C29.55 8 30 8.45 30 9V27C30 27.55 29.55 28 29 28H3C2.45 28 2 27.55 2 27V9C2 8.45 2.45 8 3 8H9.8875L11.1438 4.65C11.2875 4.2625 11.6625 4 12.0813 4H20.2687V4ZM20.2687 2H12.0813C10.8313 2 9.7125 2.775 9.275 3.94375L8.5 6H3C1.34375 6 0 7.34375 0 9V27C0 28.6562 1.34375 30 3 30H29C30.6562 30 32 28.6562 32 27V9C32 7.34375 30.6562 6 29 6H23.5L22.6063 3.625C22.2438 2.64375 21.3125 2 20.2687 2V2ZM16 25.5C11.8625 25.5 8.5 22.1375 8.5 18C8.5 13.8625 11.8625 10.5 16 10.5C20.1375 10.5 23.5 13.8625 23.5 18C23.5 22.1375 20.1375 25.5 16 25.5ZM16 12.5C12.9688 12.5 10.5 14.9688 10.5 18C10.5 21.0312 12.9688 23.5 16 23.5C19.0312 23.5 21.5 21.0312 21.5 18C21.5 14.9688 19.0312 12.5 16 12.5Z"
        fill={color}
      />
    </svg>
  );
}

Camera.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
