import React from 'react';

import PropTypes from 'prop-types';

export default function DeliveryStatus({
  color = 'currentColor',
  size = '1em',
  ...rest
}) {
  return (
    <svg
      viewBox="0 0 36 32"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      height={size}
      width={size}
      {...rest}
    >
      <path
        d="M19 26V27C19 28.0609 18.5786 29.0783 17.8284 29.8284C17.0783 30.5786 16.0609 31 15 31H5C3.93913 31 2.92172 30.5786 2.17157 29.8284C1.42143 29.0783 1 28.0609 1 27V5C1 3.93913 1.42143 2.92172 2.17157 2.17157C2.92172 1.42143 3.93913 1 5 1H14"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.1053 5.21067L11.1053 8.21067C10.7731 8.37685 10.4937 8.63227 10.2985 8.94831C10.1032 9.26435 9.99989 9.62852 10 10V16.5333C9.99989 16.9048 10.1032 17.269 10.2985 17.585C10.4937 17.9011 10.7731 18.1565 11.1053 18.3227L17.1053 21.3213C17.3831 21.4603 17.6894 21.5326 18 21.5326C18.3106 21.5326 18.6169 21.4603 18.8947 21.3213L24.8947 18.3213C25.2267 18.1553 25.506 17.9 25.7012 17.5843C25.8964 17.2685 25.9999 16.9046 26 16.5333V10C26.0001 9.62852 25.8968 9.26435 25.7015 8.94831C25.5063 8.63227 25.2269 8.37685 24.8947 8.21067L18.8947 5.21067C18.6169 5.07174 18.3106 4.9994 18 4.9994C17.6894 4.9994 17.3831 5.07174 17.1053 5.21067V5.21067Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.684 8.92267L18 12.764L10.3173 8.92267"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18 12.764V21.528"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.0493 4.06533L26.436 4.85466"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.276 2.40267L23.0987 1"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.5973 7.30667L31 7.12933"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 24.6933H11"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

DeliveryStatus.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
