import React from 'react';

import PropTypes from 'prop-types';

export default function Information({
  color = 'currentColor',
  size = '1em',
  ...rest
}) {
  return (
    <svg
      viewBox="0 0 36 32"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      height={size}
      width={size}
      {...rest}
    >
      <path
        d="M19 22H18C17.4696 22 16.9609 21.7893 16.5858 21.4142C16.2107 21.0391 16 20.5304 16 20V15C16 14.7348 15.8946 14.4804 15.7071 14.2929C15.5196 14.1054 15.2652 14 15 14H14"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.5 9C15.4011 9 15.3044 9.02932 15.2222 9.08427C15.14 9.13921 15.0759 9.2173 15.0381 9.30866C15.0002 9.40002 14.9903 9.50055 15.0096 9.59754C15.0289 9.69454 15.0765 9.78363 15.1464 9.85355C15.2164 9.92348 15.3055 9.9711 15.4025 9.99039C15.4994 10.0097 15.6 9.99978 15.6913 9.96194C15.7827 9.9241 15.8608 9.86001 15.9157 9.77778C15.9707 9.69556 16 9.59889 16 9.5C16 9.36739 15.9473 9.24022 15.8536 9.14645C15.7598 9.05268 15.6326 9 15.5 9Z"
        fill={color}
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 16C1 19.9782 2.58035 23.7936 5.3934 26.6066C8.20644 29.4196 12.0218 31 16 31C19.9782 31 23.7936 29.4196 26.6066 26.6066C29.4196 23.7936 31 19.9782 31 16C31 12.0218 29.4196 8.20644 26.6066 5.3934C23.7936 2.58035 19.9782 1 16 1C12.0218 1 8.20644 2.58035 5.3934 5.3934C2.58035 8.20644 1 12.0218 1 16V16Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

Information.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
