import React from 'react';

export default function HappyReturnsLocation() {
  return (
    <svg
      width="21"
      height="32"
      viewBox="0 0 18 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.25 8.25C5.25 9.24456 5.64509 10.1984 6.34835 10.9017C7.05161 11.6049 8.00544 12 9 12C9.99456 12 10.9484 11.6049 11.6517 10.9017C12.3549 10.1984 12.75 9.24456 12.75 8.25C12.75 7.25544 12.3549 6.30161 11.6517 5.59835C10.9484 4.89509 9.99456 4.5 9 4.5C8.00544 4.5 7.05161 4.89509 6.34835 5.59835C5.64509 6.30161 5.25 7.25544 5.25 8.25Z"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 0.75C10.9891 0.75 12.8968 1.54018 14.3033 2.9467C15.7098 4.35322 16.5 6.26088 16.5 8.25C16.5 11.657 11.426 20.2 9.625 22.915C9.55659 23.0181 9.46371 23.1027 9.35466 23.1613C9.24561 23.2198 9.12377 23.2504 9 23.2504C8.87623 23.2504 8.75439 23.2198 8.64534 23.1613C8.53629 23.1027 8.44341 23.0181 8.375 22.915C6.574 20.2 1.5 11.657 1.5 8.25C1.5 6.26088 2.29018 4.35322 3.6967 2.9467C5.10322 1.54018 7.01088 0.75 9 0.75V0.75Z"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

HappyReturnsLocation.propTypes = {};
