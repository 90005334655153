import React from 'react';

import PropTypes from 'prop-types';

export default function ViewOn({
  color = 'currentColor',
  size = '1em',
  ...rest
}) {
  return (
    <svg
      viewBox="0 0 36 32"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      height={size}
      width={size}
      {...rest}
    >
      <path
        d="M16 7.00133C10.6253 6.91066 5.06667 10.6667 1.57201 14.5133C1.20518 14.9206 1.00219 15.4492 1.00219 15.9973C1.00219 16.5454 1.20518 17.0741 1.57201 17.4813C4.99067 21.2467 10.5333 25.0893 16 24.9973C21.4667 25.0893 27.0107 21.2467 30.432 17.4813C30.7988 17.0741 31.0018 16.5454 31.0018 15.9973C31.0018 15.4492 30.7988 14.9206 30.432 14.5133C26.9333 10.6667 21.3747 6.91066 16 7.00133Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21 16C20.9997 16.9888 20.7063 17.9554 20.1567 18.7775C19.6071 19.5996 18.8261 20.2402 17.9125 20.6184C16.9988 20.9967 15.9936 21.0955 15.0237 20.9024C14.0539 20.7093 13.1631 20.233 12.464 19.5337C11.7649 18.8344 11.2888 17.9435 11.096 16.9736C10.9032 16.0038 11.0023 14.9985 11.3807 14.0849C11.7592 13.1714 12.4001 12.3906 13.2223 11.8412C14.0445 11.2919 15.0112 10.9987 16 10.9987C16.6568 10.9985 17.3071 11.1277 17.9139 11.379C18.5207 11.6303 19.0721 11.9987 19.5364 12.4632C20.0007 12.9277 20.369 13.4791 20.6201 14.086C20.8713 14.6928 21.0004 15.3432 21 16Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

ViewOn.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
