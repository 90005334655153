import React from 'react';

import PropTypes from 'prop-types';

export default function QuestionCircle({
  color = 'currentColor',
  size = '1em',
  ...rest
}) {
  return (
    <svg
      viewBox="0 0 36 32"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      height={size}
      width={size}
      {...rest}
    >
      <path
        d="M12 12C12.0001 11.2667 12.2019 10.5476 12.5831 9.92116C12.9643 9.29476 13.5104 8.78521 14.1617 8.44821C14.813 8.1112 15.5444 7.95971 16.2759 8.01029C17.0074 8.06087 17.711 8.31157 18.3097 8.735C18.9084 9.15842 19.3792 9.73828 19.6706 10.4112C19.962 11.0841 20.0628 11.8242 19.962 12.5505C19.8613 13.2768 19.5628 13.9615 19.0992 14.5296C18.6356 15.0978 18.0247 15.5276 17.3333 15.772C16.9432 15.9099 16.6055 16.1654 16.3667 16.5033C16.1279 16.8412 15.9998 17.2449 16 17.6587V19"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 23C15.9011 23 15.8044 23.0293 15.7222 23.0843C15.64 23.1392 15.5759 23.2173 15.5381 23.3087C15.5002 23.4 15.4903 23.5006 15.5096 23.5975C15.5289 23.6945 15.5765 23.7836 15.6464 23.8536C15.7164 23.9235 15.8055 23.9711 15.9025 23.9904C15.9994 24.0097 16.1 23.9998 16.1913 23.9619C16.2827 23.9241 16.3608 23.86 16.4157 23.7778C16.4707 23.6956 16.5 23.5989 16.5 23.5C16.5 23.3674 16.4473 23.2402 16.3536 23.1464C16.2598 23.0527 16.1326 23 16 23Z"
        fill={color}
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 16C1 19.9782 2.58035 23.7936 5.3934 26.6066C8.20644 29.4196 12.0218 31 16 31C19.9782 31 23.7936 29.4196 26.6066 26.6066C29.4196 23.7936 31 19.9782 31 16C31 12.0218 29.4196 8.20644 26.6066 5.3934C23.7936 2.58035 19.9782 1 16 1C12.0218 1 8.20644 2.58035 5.3934 5.3934C2.58035 8.20644 1 12.0218 1 16V16Z"
        stroke={color}
        strokeWidth="1.5"
      />
    </svg>
  );
}

QuestionCircle.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
