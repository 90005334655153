import React from 'react';

import PropTypes from 'prop-types';

export default function Edit({
  color = 'currentColor',
  size = '1em',
  ...rest
}) {
  return (
    <svg
      viewBox="0 0 36 32"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      height={size}
      width={size}
      {...rest}
    >
      <path
        d="M29.5867 2.41333C29.1325 1.96108 28.5932 1.60329 27.9999 1.36065C27.4067 1.118 26.7712 0.995305 26.1303 0.999644C25.4893 1.00398 24.8556 1.13527 24.2657 1.38593C23.6758 1.63658 23.1414 2.00163 22.6933 2.46L3.36 21.7933L1 31L10.2067 28.6387L29.54 9.30533C29.9985 8.85751 30.3636 8.32325 30.6144 7.73343C30.8651 7.14362 30.9965 6.50995 31.0008 5.86907C31.0051 5.22818 30.8824 4.59279 30.6397 3.99964C30.3969 3.40648 30.039 2.86732 29.5867 2.41333V2.41333Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.1413 3.01333L28.9867 9.85866"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.36133 21.792L10.2133 28.632"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

Edit.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
