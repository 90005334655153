import React from 'react';

import PropTypes from 'prop-types';

export default function Leaf({
  color = 'currentColor',
  size = '1em',
  ...rest
}) {
  return (
    <svg
      viewBox="0 0 36 32"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      height={size}
      width={size}
      {...rest}
    >
      <path
        d="M13.6667 28.12C11.0039 28.12 8.45018 27.0622 6.56731 25.1794C4.68445 23.2965 3.62666 20.7428 3.62666 18.08C3.62666 3.52001 21.1467 11 29.5867 4.00001C29.683 3.94109 29.7937 3.90991 29.9067 3.90991C30.0196 3.90991 30.1303 3.94109 30.2267 4.00001C30.3301 4.04471 30.4208 4.11441 30.4906 4.20285C30.5604 4.29129 30.6072 4.39569 30.6267 4.50667C33.2 19.52 22.0933 28.12 13.6667 28.12Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.68 15.4C13.5191 16.5455 9.6203 18.4882 6.2 21.12L1 25.56"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

Leaf.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
