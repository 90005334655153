import React from 'react';

import PropTypes from 'prop-types';

export default function AlertTriangle({
  color = 'currentColor',
  size = '1em',
  ...rest
}) {
  return (
    <svg
      viewBox="0 0 36 32"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      height={size}
      width={size}
      {...rest}
    >
      <path
        d="M16 25C15.9011 25 15.8044 25.0293 15.7222 25.0843C15.64 25.1392 15.5759 25.2173 15.5381 25.3087C15.5002 25.4 15.4903 25.5006 15.5096 25.5975C15.5289 25.6945 15.5765 25.7836 15.6464 25.8536C15.7164 25.9235 15.8055 25.9711 15.9025 25.9904C15.9994 26.0097 16.1 25.9998 16.1913 25.9619C16.2827 25.9241 16.3608 25.86 16.4157 25.7778C16.4707 25.6956 16.5 25.5989 16.5 25.5C16.5 25.3674 16.4473 25.2402 16.3536 25.1464C16.2598 25.0527 16.1326 25 16 25Z"
        fill={color}
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 21V11"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M18.1613 2.34667C17.9629 1.94269 17.6552 1.60241 17.2732 1.36444C16.8912 1.12646 16.4501 1.00032 16 1.00032C15.5499 1.00032 15.1088 1.12646 14.7268 1.36444C14.3448 1.60241 14.0371 1.94269 13.8387 2.34667L1.20801 28.0773C1.05597 28.3866 0.985254 28.7296 1.00255 29.0738C1.01984 29.418 1.12457 29.7521 1.30684 30.0446C1.48912 30.3371 1.74292 30.5783 2.04431 30.7455C2.34569 30.9126 2.6847 31.0002 3.02934 31H28.9707C29.3153 31.0002 29.6543 30.9126 29.9557 30.7455C30.2571 30.5783 30.5109 30.3371 30.6932 30.0446C30.8754 29.7521 30.9802 29.418 30.9975 29.0738C31.0148 28.7296 30.944 28.3866 30.792 28.0773L18.1613 2.34667Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

AlertTriangle.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
