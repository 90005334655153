import React from 'react';

import PropTypes from 'prop-types';

export default function CaretUp({
  color = 'currentColor',
  size = '1em',
  ...rest
}) {
  return (
    <svg
      viewBox="0 0 36 32"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      height={size}
      width={size}
      {...rest}
    >
      <path
        d="M1 22.9187L15.2933 8.62667C15.3861 8.53378 15.4962 8.4601 15.6175 8.40982C15.7388 8.35954 15.8687 8.33366 16 8.33366C16.1313 8.33366 16.2612 8.35954 16.3825 8.40982C16.5038 8.4601 16.6139 8.53378 16.7067 8.62667L31 22.9187"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

CaretUp.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
