import React from 'react';

import PropTypes from 'prop-types';

export default function Dislike({
  color = 'currentColor',
  size = '1em',
  ...rest
}) {
  return (
    <svg
      viewBox="0 0 36 32"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      height={size}
      width={size}
      {...rest}
    >
      <path
        d="M25 2.99733C24.2971 2.98798 23.5985 2.8853 22.9227 2.692L18.0773 1.308C17.3632 1.10389 16.6241 1.00023 15.8813 1H9.36799C7.38475 0.999975 5.47219 1.73661 4.00137 3.06701C2.53055 4.3974 1.60632 6.2267 1.408 8.2L1.008 15C0.937253 16.388 1.35058 17.7576 2.17739 18.8748C3.00419 19.9919 4.1932 20.7874 5.54133 21.1253L7.48133 21.5493C7.91469 21.6568 8.29963 21.9062 8.57485 22.2577C8.85006 22.6093 8.99972 23.0429 8.99999 23.4893V28C8.99999 28.7957 9.31607 29.5587 9.87867 30.1213C10.4413 30.6839 11.2043 31 12 31C12.7956 31 13.5587 30.6839 14.1213 30.1213C14.6839 29.5587 15 28.7957 15 28V25.928C15 23.2758 16.0536 20.7323 17.9289 18.8569C19.8043 16.9816 22.3478 15.928 25 15.928V2.99733Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31 1H25V19H31V1Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

Dislike.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
