import React from 'react';

export default function ToggleDown() {
  return (
    <svg
      width="10"
      height="6"
      viewBox="0 0 10 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 0.874023L5.18844 4.68522C5.16371 4.70999 5.13434 4.72964 5.102 4.74305C5.06967 4.75646 5.035 4.76336 5 4.76336C4.965 4.76336 4.93033 4.75646 4.898 4.74305C4.86566 4.72964 4.83629 4.70999 4.81156 4.68522L1 0.874023"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

ToggleDown.propTypes = {};
