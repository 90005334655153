import React from 'react';

import PropTypes from 'prop-types';

export default function AddToCart({
  color = 'currentColor',
  size = '1em',
  ...rest
}) {
  return (
    <svg
      viewBox="0 0 36 32"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      height={size}
      width={size}
      {...rest}
    >
      <path
        d="M6 27.9573C6 28.6204 6.26339 29.2563 6.73223 29.7251C7.20107 30.1939 7.83696 30.4573 8.5 30.4573C9.16304 30.4573 9.79893 30.1939 10.2678 29.7251C10.7366 29.2563 11 28.6204 11 27.9573C11 27.2943 10.7366 26.6584 10.2678 26.1896C9.79893 25.7207 9.16304 25.4573 8.5 25.4573C7.83696 25.4573 7.20107 25.7207 6.73223 26.1896C6.26339 26.6584 6 27.2943 6 27.9573V27.9573Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 27.9573C16 28.6204 16.2634 29.2563 16.7322 29.7251C17.2011 30.1939 17.837 30.4573 18.5 30.4573C19.163 30.4573 19.7989 30.1939 20.2678 29.7251C20.7366 29.2563 21 28.6204 21 27.9573C21 27.2943 20.7366 26.6584 20.2678 26.1896C19.7989 25.7207 19.163 25.4573 18.5 25.4573C17.837 25.4573 17.2011 25.7207 16.7322 26.1896C16.2634 26.6584 16 27.2943 16 27.9573V27.9573Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 9.45734L4.10533 19.9733C4.21904 20.3991 4.47023 20.7755 4.81984 21.0438C5.16946 21.3122 5.59793 21.4576 6.03867 21.4573H20.4627C20.9041 21.4584 21.3335 21.3135 21.6839 21.0451C22.0343 20.7766 22.2861 20.3998 22.4 19.9733L26.7173 3.768C26.8875 3.12926 27.264 2.56465 27.7882 2.16197C28.3124 1.75929 28.955 1.5411 29.616 1.54134H31"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13 8.45734V16.4573"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 12.4573H17"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

AddToCart.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
