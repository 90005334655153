import React from 'react';

import PropTypes from 'prop-types';

export default function Like({
  color = 'currentColor',
  size = '1em',
  ...rest
}) {
  return (
    <svg
      viewBox="0 0 36 32"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      height={size}
      width={size}
      {...rest}
    >
      <path
        d="M7 29C7.70453 29.0238 8.40331 29.1358 9.08 29.3333C14.4133 30.8533 14.7067 31.0267 16.12 31.0267H22.6667C24.6568 31.0366 26.5792 30.3045 28.0585 28.9731C29.5377 27.6418 30.4677 25.8069 30.6667 23.8267L31.0667 17.0267C31.1387 15.639 30.7258 14.2694 29.8987 13.1528C29.0716 12.0362 27.8818 11.2421 26.5333 10.9067C25.0933 10.5467 23.08 10.5467 23.08 8.53333V4C23.08 3.20435 22.7639 2.44129 22.2013 1.87868C21.6387 1.31607 20.8757 1 20.08 1C19.2844 1 18.5213 1.31607 17.9587 1.87868C17.3961 2.44129 17.08 3.20435 17.08 4C17.08 4.13333 17.96 16.0667 7.08 16.0667L7 29Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 13H1V31H7V13Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

Like.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
