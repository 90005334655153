import React from 'react';

import PropTypes from 'prop-types';

export default function Heart({
  color = 'currentColor',
  size = '1em',
  ...rest
}) {
  return (
    <svg
      viewBox="0 0 36 32"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      height={size}
      width={size}
      {...rest}
    >
      <path
        d="M16 29.1253L3.216 15.792C2.09245 14.6693 1.35088 13.2214 1.09648 11.6536C0.842077 10.0858 1.08775 8.47767 1.79866 7.05733V7.05733C2.33474 5.98549 3.11782 5.05636 4.08339 4.34651C5.04896 3.63666 6.16938 3.16639 7.35234 2.97446C8.53529 2.78254 9.74692 2.87444 10.8874 3.24259C12.0279 3.61075 13.0645 4.24463 13.912 5.092L16 7.17866L18.088 5.092C18.9355 4.24463 19.9721 3.61075 21.1126 3.24259C22.2531 2.87444 23.4647 2.78254 24.6477 2.97446C25.8306 3.16639 26.951 3.63666 27.9166 4.34651C28.8822 5.05636 29.6653 5.98549 30.2013 7.05733C30.9113 8.47712 31.1565 10.0843 30.9024 11.6512C30.6483 13.2181 29.9076 14.6654 28.7853 15.788L16 29.1253Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

Heart.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
