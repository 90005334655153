import React from 'react';

import PropTypes from 'prop-types';

export default function Filter({
  color = 'currentColor',
  size = '1em',
  ...rest
}) {
  return (
    <svg
      viewBox="0 0 36 32"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      height={size}
      width={size}
      {...rest}
    >
      <g clipPath="url(#clip0_1576_7532)">
        <path
          d="M29.998 0H2.0024C0.225841 0 -0.670534 2.15544 0.588153 3.41419L12 14.8284V26.5C12 26.7851 12.061 27.0668 12.1787 27.3264C12.2965 27.586 12.4685 27.8174 12.683 28.0051L16.683 31.5041C17.9604 32.6218 20 31.7341 20 29.9989V14.8284L31.4122 3.41419C32.6685 2.158 31.7782 0 29.998 0ZM18 14V30L14 26.5V14L2.00003 2H30L18 14Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_1576_7532">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

Filter.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
