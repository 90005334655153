import React from 'react';

import PropTypes from 'prop-types';

export default function Favorites({
  color = 'currentColor',
  count = 0,
  size = '1em',
  ...rest
}) {
  return (
    <svg
      viewBox="0 0 36 32"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      height={size}
      width={size}
      {...rest}
    >
      <path
        d="M11.5205 1.77325C10.2619 0.632934 8.61438 5.29852e-05 6.90451 0C5.99949 0.000928397 5.10359 0.178189 4.26837 0.521582C3.43315 0.864974 2.6751 1.36772 2.03784 2.00088C-0.680242 4.69035 -0.679087 8.89698 2.04015 11.5751L10.5097 19.92C10.7061 20.2603 11.085 20.48 11.5205 20.48C11.6993 20.4783 11.8752 20.4353 12.0341 20.3544C12.193 20.2736 12.3305 20.1572 12.4354 20.0145L21.0009 11.5751C23.7201 8.89584 23.7201 4.69035 20.9986 1.99633C20.3616 1.36433 19.6042 0.862606 18.7698 0.520005C17.9354 0.177404 17.0405 0.000682446 16.1365 0C14.4267 0.000275388 12.7793 0.633126 11.5205 1.77325V1.77325ZM19.3652 3.60568C21.1707 5.39373 21.1718 8.18677 19.3675 9.96571L11.5205 17.6972L3.67354 9.96571C1.86919 8.18677 1.87034 5.39373 3.67123 3.61024C4.54915 2.74979 5.69737 2.27631 6.90451 2.27631C8.11164 2.27631 9.25525 2.74979 10.1262 3.60796L10.7038 4.17704C10.811 4.28282 10.9383 4.36675 11.0784 4.42401C11.2186 4.48127 11.3688 4.51075 11.5205 4.51075C11.6722 4.51075 11.8224 4.48127 11.9626 4.42401C12.1027 4.36675 12.23 4.28282 12.3372 4.17704L12.9148 3.60796C14.6614 1.89048 17.6209 1.89503 19.3652 3.60568V3.60568Z"
        fill={color}
      />
      <path
        d="M21.0191 22.188H22.6991V17.58H21.2831V16.56C21.6751 16.488 22.0151 16.4 22.3031 16.296C22.5911 16.192 22.8671 16.056 23.1311 15.888H24.3431V22.188H25.7951V23.52H21.0191V22.188ZM26.7495 22.188H28.4295V17.58H27.0135V16.56C27.4055 16.488 27.7455 16.4 28.0335 16.296C28.3215 16.192 28.5975 16.056 28.8615 15.888H30.0735V22.188H31.5255V23.52H26.7495V22.188Z"
        fill={color}
      />
    </svg>
  );
}

Favorites.propTypes = {
  color: PropTypes.string,
  count: PropTypes.number,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
