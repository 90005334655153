import React from 'react';

import PropTypes from 'prop-types';

export default function NavigationLeft({
  color = 'currentColor',
  size = '1em',
  ...rest
}) {
  return (
    <svg
      viewBox="0 0 36 32"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      height={size}
      width={size}
      {...rest}
    >
      <path
        d="M10.0013 15.996H22.0013"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.0013 20.996L10.0013 15.996L15.0013 10.996"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.00133 15.996C2.00133 19.709 3.47632 23.27 6.10183 25.8955C8.72734 28.521 12.2883 29.996 16.0013 29.996C19.7144 29.996 23.2753 28.521 25.9008 25.8955C28.5263 23.27 30.0013 19.709 30.0013 15.996C30.0013 12.283 28.5263 8.72202 25.9008 6.09651C23.2753 3.471 19.7144 1.996 16.0013 1.996C12.2883 1.996 8.72734 3.471 6.10183 6.09651C3.47632 8.72202 2.00133 12.283 2.00133 15.996V15.996Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

NavigationLeft.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
