import React from 'react';

import PropTypes from 'prop-types';

export default function Leggings({
  color = 'currentColor',
  width = '13',
  height = '30',
  ...rest
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 13 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.09827 1.05856C2.63842 1.62646 1.68026 3.15329 1.3507 3.42885C1.02113 3.70441 0.202972 5.58427 0.612053 6.6201C1.02113 7.65593 1.69629 8.40298 1.69629 8.73848C1.69629 9.07398 2.26191 13.1307 1.9791 13.9203C1.69629 14.71 1.69629 17.8071 1.69629 18.4493C1.69629 19.0914 1.05644 21.2749 1.05644 22.3353C1.05644 23.3958 1.41348 27.6616 1.69629 27.6616C1.9791 27.6616 4.09827 25.1667 4.09827 23.9187C4.09827 22.6706 4.09827 19.5477 4.09827 19.5477L5.55408 13.1749L8.44489 16.5265C8.44489 16.5265 6.80381 18.3701 6.50063 18.8367C6.19745 19.3032 4.04884 25.6285 3.56528 26.3949C3.08172 27.1612 2.92066 27.6616 2.92066 27.6616C2.92066 27.6616 4.20132 28.9856 4.88525 28.9856C5.56919 28.9856 8.62109 21.1934 10.202 19.5477C11.7829 17.902 11.7829 16.1956 11.7829 16.1956C11.7829 16.1956 11.0286 12.1816 6.97465 6.6201C6.50063 5.31024 8.15169 4.34063 8.15169 4.34063C8.15169 4.34063 5.55812 0.490663 4.09827 1.05856Z"
        stroke={color}
      />
    </svg>
  );
}

Leggings.propTypes = {
  color: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
