import React from 'react';

export default function VideoShoppingFeature(props) {
  return (
    <svg
      width="22"
      height="20"
      viewBox="0 0 22 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="video-feature-icon">
        <path
          id="Vector"
          d="M20.625 7.14286V17.1429C20.625 17.933 20.0105 18.5714 19.25 18.5714H2.75C1.98945 18.5714 1.375 17.933 1.375 17.1429V7.14286H20.625ZM20.625 5.71429H16.4398L20.191 1.81696C20.4574 2.07589 20.625 2.44643 20.625 2.85714V5.71429ZM9.90859 5.71429L14.0336 1.42857H18.6227L14.4977 5.71429H9.90859ZM7.96211 5.71429H3.37734L7.50234 1.42857H12.0914L7.96641 5.71429H7.96211ZM2.75 1.42857H5.56016L1.43516 5.71429H1.375V2.85714C1.375 2.06696 1.98945 1.42857 2.75 1.42857ZM22 5.71429V2.85714C22 1.28125 20.7668 0 19.25 0H2.75C1.2332 0 0 1.28125 0 2.85714V5.71429V6.42857V7.14286V17.1429C0 18.7188 1.2332 20 2.75 20H19.25C20.7668 20 22 18.7188 22 17.1429V7.14286V6.42857V5.71429ZM9.28555 8.66964C9.075 8.54018 8.80859 8.54018 8.59375 8.66518C8.37891 8.79018 8.2457 9.02679 8.2457 9.28571V16.4286C8.2457 16.683 8.37891 16.9196 8.59375 17.0491C8.80859 17.1786 9.0707 17.1741 9.28555 17.0446L15.1293 13.4732C15.3398 13.3437 15.4687 13.1116 15.4687 12.8571C15.4687 12.6027 15.3398 12.3705 15.1293 12.2411L9.28555 8.66964ZM13.4234 12.8571L9.625 15.1786V10.5357L13.4234 12.8571Z"
          fill="#3E4039"
        />
      </g>
    </svg>
  );
}
