import React from 'react';

import PropTypes from 'prop-types';

export default function Stars({
  color = 'currentColor',
  size = '1em',
  ...rest
}) {
  return (
    <svg
      viewBox="0 0 36 32"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      height={size}
      width={size}
      {...rest}
    >
      <path
        d="M24.8253 17.5013L26.768 20.9987H30.076C30.2569 20.9955 30.4345 21.0466 30.5861 21.1453C30.7376 21.2441 30.8561 21.386 30.9263 21.5527C30.9964 21.7194 31.0151 21.9033 30.9797 22.0807C30.9444 22.2581 30.8567 22.4209 30.728 22.548L27.6907 25.8667L29.3733 29.7333C29.4473 29.9104 29.4639 30.1061 29.4207 30.2931C29.3774 30.48 29.2766 30.6487 29.1324 30.7752C28.9881 30.9018 28.8078 30.9798 28.6168 30.9984C28.4259 31.0169 28.2339 30.9751 28.068 30.8787L24 28.5867L19.9333 30.8747C19.7673 30.9707 19.5754 31.0122 19.3846 30.9935C19.1938 30.9748 19.0136 30.8968 18.8694 30.7704C18.7252 30.644 18.6243 30.4756 18.5807 30.2889C18.5372 30.1022 18.5532 29.9065 18.6267 29.7293L20.3107 25.8627L17.2733 22.5467C17.1446 22.4196 17.057 22.2568 17.0216 22.0794C16.9863 21.902 17.0049 21.7181 17.075 21.5514C17.1452 21.3846 17.2637 21.2428 17.4153 21.144C17.5668 21.0453 17.7445 20.9942 17.9253 20.9973H21.2333L23.1747 17.5C23.2521 17.3476 23.3702 17.2196 23.516 17.1302C23.6618 17.0408 23.8294 16.9936 24.0004 16.9937C24.1714 16.9939 24.339 17.0414 24.4846 17.131C24.6302 17.2206 24.7481 17.3488 24.8253 17.5013V17.5013Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.82533 17.5013L10.7667 20.9987H14.0747C14.2553 20.9959 14.4327 21.0472 14.5841 21.1459C14.7354 21.2447 14.8538 21.3864 14.924 21.5529C14.9942 21.7194 15.0131 21.9031 14.9781 22.0804C14.9432 22.2577 14.8561 22.4206 14.728 22.548L11.6893 25.8667L13.3733 29.7333C13.4467 29.9105 13.4628 30.1062 13.4193 30.2929C13.3757 30.4796 13.2748 30.648 13.1306 30.7744C12.9864 30.9008 12.8062 30.9788 12.6154 30.9975C12.4245 31.0162 12.2327 30.9747 12.0667 30.8787L8 28.5867L3.932 30.8747C3.76645 30.9703 3.57514 31.0117 3.38487 30.9931C3.19461 30.9745 3.01493 30.8968 2.87101 30.771C2.7271 30.6452 2.62616 30.4775 2.58234 30.2914C2.53852 30.1053 2.55402 29.9102 2.62667 29.7333L4.30933 25.8667L1.272 22.548C1.1433 22.4209 1.05563 22.2582 1.02029 22.0808C0.984945 21.9034 1.00355 21.7194 1.07372 21.5527C1.14388 21.386 1.26238 21.2441 1.41393 21.1453C1.56548 21.0466 1.74314 20.9955 1.924 20.9987H5.232L7.17467 17.5013C7.25198 17.3488 7.37002 17.2207 7.51571 17.1313C7.6614 17.0418 7.82903 16.9944 8 16.9944C8.17097 16.9944 8.3386 17.0418 8.48429 17.1313C8.62998 17.2207 8.74802 17.3488 8.82533 17.5013V17.5013Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.8253 1.50801L18.7653 5.00134H22.072C22.2529 4.99818 22.4305 5.04927 22.5821 5.14802C22.7336 5.24677 22.8521 5.38865 22.9223 5.55537C22.9924 5.7221 23.0111 5.90602 22.9757 6.08342C22.9404 6.26082 22.8527 6.42357 22.724 6.55067L19.688 9.86667L21.3707 13.7333C21.4447 13.9104 21.4612 14.1062 21.418 14.2931C21.3748 14.48 21.2739 14.6487 21.1297 14.7752C20.9855 14.9018 20.8051 14.9798 20.6142 14.9984C20.4232 15.017 20.2312 14.9751 20.0653 14.8787L16 12.588L11.9347 14.8747C11.7688 14.9711 11.5768 15.013 11.3858 14.9944C11.1948 14.9758 11.0145 14.8978 10.8703 14.7712C10.7261 14.6447 10.6252 14.476 10.582 14.2891C10.5388 14.1022 10.5553 13.9064 10.6293 13.7293L12.312 9.86667L9.276 6.55067C9.1473 6.42357 9.05963 6.26082 9.02428 6.08342C8.98894 5.90602 9.00755 5.7221 9.07771 5.55537C9.14788 5.38865 9.26637 5.24677 9.41793 5.14802C9.56948 5.04927 9.74714 4.99818 9.928 5.00134H13.2333L15.176 1.50801C15.2534 1.35586 15.3715 1.2281 15.517 1.13886C15.6625 1.04963 15.8299 1.0024 16.0007 1.0024C16.1714 1.0024 16.3388 1.04963 16.4843 1.13886C16.6299 1.2281 16.7479 1.35586 16.8253 1.50801V1.50801Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.8253 17.5013L26.768 20.9987H30.076C30.2569 20.9955 30.4345 21.0466 30.5861 21.1453C30.7376 21.2441 30.8561 21.386 30.9263 21.5527C30.9964 21.7194 31.0151 21.9033 30.9797 22.0807C30.9444 22.2581 30.8567 22.4209 30.728 22.548L27.6907 25.8667L29.3733 29.7333C29.4473 29.9104 29.4639 30.1061 29.4207 30.2931C29.3774 30.48 29.2766 30.6487 29.1324 30.7752C28.9881 30.9018 28.8078 30.9798 28.6168 30.9984C28.4259 31.0169 28.2339 30.9751 28.068 30.8787L24 28.5867L19.9333 30.8747C19.7673 30.9707 19.5754 31.0122 19.3846 30.9935C19.1938 30.9748 19.0136 30.8968 18.8694 30.7704C18.7252 30.644 18.6243 30.4756 18.5807 30.2889C18.5372 30.1022 18.5532 29.9065 18.6267 29.7293L20.3107 25.8627L17.2733 22.5467C17.1446 22.4196 17.057 22.2568 17.0216 22.0794C16.9863 21.902 17.0049 21.7181 17.075 21.5514C17.1452 21.3846 17.2637 21.2428 17.4153 21.144C17.5668 21.0453 17.7445 20.9942 17.9253 20.9973H21.2333L23.1747 17.5C23.2521 17.3476 23.3702 17.2196 23.516 17.1302C23.6618 17.0408 23.8294 16.9936 24.0004 16.9937C24.1714 16.9939 24.339 17.0414 24.4846 17.131C24.6302 17.2206 24.7481 17.3488 24.8253 17.5013V17.5013Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.82533 17.5013L10.7667 20.9987H14.0747C14.2553 20.9959 14.4327 21.0472 14.5841 21.1459C14.7354 21.2447 14.8538 21.3864 14.924 21.5529C14.9942 21.7194 15.0131 21.9031 14.9781 22.0804C14.9432 22.2577 14.8561 22.4206 14.728 22.548L11.6893 25.8667L13.3733 29.7333C13.4467 29.9105 13.4628 30.1062 13.4193 30.2929C13.3757 30.4796 13.2748 30.648 13.1306 30.7744C12.9864 30.9008 12.8062 30.9788 12.6154 30.9975C12.4245 31.0162 12.2327 30.9747 12.0667 30.8787L8 28.5867L3.932 30.8747C3.76645 30.9703 3.57514 31.0117 3.38487 30.9931C3.19461 30.9745 3.01493 30.8968 2.87101 30.771C2.7271 30.6452 2.62616 30.4775 2.58234 30.2914C2.53852 30.1053 2.55402 29.9102 2.62667 29.7333L4.30933 25.8667L1.272 22.548C1.1433 22.4209 1.05563 22.2582 1.02029 22.0808C0.984945 21.9034 1.00355 21.7194 1.07372 21.5527C1.14388 21.386 1.26238 21.2441 1.41393 21.1453C1.56548 21.0466 1.74314 20.9955 1.924 20.9987H5.232L7.17467 17.5013C7.25198 17.3488 7.37002 17.2207 7.51571 17.1313C7.6614 17.0418 7.82903 16.9944 8 16.9944C8.17097 16.9944 8.3386 17.0418 8.48429 17.1313C8.62998 17.2207 8.74802 17.3488 8.82533 17.5013V17.5013Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.8253 1.50801L18.7653 5.00134H22.072C22.2529 4.99818 22.4305 5.04927 22.5821 5.14802C22.7336 5.24677 22.8521 5.38865 22.9223 5.55537C22.9924 5.7221 23.0111 5.90602 22.9757 6.08342C22.9404 6.26082 22.8527 6.42357 22.724 6.55067L19.688 9.86667L21.3707 13.7333C21.4447 13.9104 21.4612 14.1062 21.418 14.2931C21.3748 14.48 21.2739 14.6487 21.1297 14.7752C20.9855 14.9018 20.8051 14.9798 20.6142 14.9984C20.4232 15.017 20.2312 14.9751 20.0653 14.8787L16 12.588L11.9347 14.8747C11.7688 14.9711 11.5768 15.013 11.3858 14.9944C11.1948 14.9758 11.0145 14.8978 10.8703 14.7712C10.7261 14.6447 10.6252 14.476 10.582 14.2891C10.5388 14.1022 10.5553 13.9064 10.6293 13.7293L12.312 9.86667L9.276 6.55067C9.1473 6.42357 9.05963 6.26082 9.02428 6.08342C8.98894 5.90602 9.00755 5.7221 9.07771 5.55537C9.14788 5.38865 9.26637 5.24677 9.41793 5.14802C9.56948 5.04927 9.74714 4.99818 9.928 5.00134H13.2333L15.176 1.50801C15.2534 1.35586 15.3715 1.2281 15.517 1.13886C15.6625 1.04963 15.8299 1.0024 16.0007 1.0024C16.1714 1.0024 16.3388 1.04963 16.4843 1.13886C16.6299 1.2281 16.7479 1.35586 16.8253 1.50801V1.50801Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.8253 17.5013L26.768 20.9987H30.076C30.2569 20.9955 30.4345 21.0466 30.5861 21.1453C30.7376 21.2441 30.8561 21.386 30.9263 21.5527C30.9964 21.7194 31.0151 21.9033 30.9797 22.0807C30.9444 22.2581 30.8567 22.4209 30.728 22.548L27.6907 25.8667L29.3733 29.7333C29.4473 29.9104 29.4639 30.1061 29.4207 30.2931C29.3774 30.48 29.2766 30.6487 29.1324 30.7752C28.9881 30.9018 28.8078 30.9798 28.6168 30.9984C28.4259 31.0169 28.2339 30.9751 28.068 30.8787L24 28.5867L19.9333 30.8747C19.7673 30.9707 19.5754 31.0122 19.3846 30.9935C19.1938 30.9748 19.0136 30.8968 18.8694 30.7704C18.7252 30.644 18.6243 30.4756 18.5807 30.2889C18.5372 30.1022 18.5532 29.9065 18.6267 29.7293L20.3107 25.8627L17.2733 22.5467C17.1446 22.4196 17.057 22.2568 17.0216 22.0794C16.9863 21.902 17.0049 21.7181 17.075 21.5514C17.1452 21.3846 17.2637 21.2428 17.4153 21.144C17.5668 21.0453 17.7445 20.9942 17.9253 20.9973H21.2333L23.1747 17.5C23.2521 17.3476 23.3702 17.2196 23.516 17.1302C23.6618 17.0408 23.8294 16.9936 24.0004 16.9937C24.1714 16.9939 24.339 17.0414 24.4846 17.131C24.6302 17.2206 24.7481 17.3488 24.8253 17.5013V17.5013Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.82533 17.5013L10.7667 20.9987H14.0747C14.2553 20.9959 14.4327 21.0472 14.5841 21.1459C14.7354 21.2447 14.8538 21.3864 14.924 21.5529C14.9942 21.7194 15.0131 21.9031 14.9781 22.0804C14.9432 22.2577 14.8561 22.4206 14.728 22.548L11.6893 25.8667L13.3733 29.7333C13.4467 29.9105 13.4628 30.1062 13.4193 30.2929C13.3757 30.4796 13.2748 30.648 13.1306 30.7744C12.9864 30.9008 12.8062 30.9788 12.6154 30.9975C12.4245 31.0162 12.2327 30.9747 12.0667 30.8787L8 28.5867L3.932 30.8747C3.76645 30.9703 3.57514 31.0117 3.38487 30.9931C3.19461 30.9745 3.01493 30.8968 2.87101 30.771C2.7271 30.6452 2.62616 30.4775 2.58234 30.2914C2.53852 30.1053 2.55402 29.9102 2.62667 29.7333L4.30933 25.8667L1.272 22.548C1.1433 22.4209 1.05563 22.2582 1.02029 22.0808C0.984945 21.9034 1.00355 21.7194 1.07372 21.5527C1.14388 21.386 1.26238 21.2441 1.41393 21.1453C1.56548 21.0466 1.74314 20.9955 1.924 20.9987H5.232L7.17467 17.5013C7.25198 17.3488 7.37002 17.2207 7.51571 17.1313C7.6614 17.0418 7.82903 16.9944 8 16.9944C8.17097 16.9944 8.3386 17.0418 8.48429 17.1313C8.62998 17.2207 8.74802 17.3488 8.82533 17.5013V17.5013Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.8253 1.50801L18.7653 5.00134H22.072C22.2529 4.99818 22.4305 5.04927 22.5821 5.14802C22.7336 5.24677 22.8521 5.38865 22.9223 5.55537C22.9924 5.7221 23.0111 5.90602 22.9757 6.08342C22.9404 6.26082 22.8527 6.42357 22.724 6.55067L19.688 9.86667L21.3707 13.7333C21.4447 13.9104 21.4612 14.1062 21.418 14.2931C21.3748 14.48 21.2739 14.6487 21.1297 14.7752C20.9855 14.9018 20.8051 14.9798 20.6142 14.9984C20.4232 15.017 20.2312 14.9751 20.0653 14.8787L16 12.588L11.9347 14.8747C11.7688 14.9711 11.5768 15.013 11.3858 14.9944C11.1948 14.9758 11.0145 14.8978 10.8703 14.7712C10.7261 14.6447 10.6252 14.476 10.582 14.2891C10.5388 14.1022 10.5553 13.9064 10.6293 13.7293L12.312 9.86667L9.276 6.55067C9.1473 6.42357 9.05963 6.26082 9.02428 6.08342C8.98894 5.90602 9.00755 5.7221 9.07771 5.55537C9.14788 5.38865 9.26637 5.24677 9.41793 5.14802C9.56948 5.04927 9.74714 4.99818 9.928 5.00134H13.2333L15.176 1.50801C15.2534 1.35586 15.3715 1.2281 15.517 1.13886C15.6625 1.04963 15.8299 1.0024 16.0007 1.0024C16.1714 1.0024 16.3388 1.04963 16.4843 1.13886C16.6299 1.2281 16.7479 1.35586 16.8253 1.50801V1.50801Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

Stars.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
