import React from 'react';

import PropTypes from 'prop-types';

export default function Shipping2({
  color = 'currentColor',
  size = '1em',
  ...rest
}) {
  return (
    <svg
      viewBox="0 0 36 32"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      height={size}
      width={size}
      {...rest}
    >
      <path
        d="M1.02133 17.6293L4.93733 19.612"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.14667 12.312L4.896 14.32"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.72667 7.89067L4.97867 9.27867"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.4787 6.868L25.4787 12.488"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.7493 4.68534L10.4987 8.90134C10.0387 9.13996 9.65339 9.50083 9.38522 9.94425C9.11705 10.3877 8.97637 10.8965 8.97867 11.4147V20.5867C8.9765 21.105 9.11738 21.6139 9.38579 22.0573C9.6542 22.5008 10.0398 22.8616 10.5 23.1L18.7493 27.3147C19.13 27.5098 19.5516 27.6116 19.9793 27.6116C20.4071 27.6116 20.8287 27.5098 21.2093 27.3147L29.4587 23.1C29.9188 22.8615 30.3041 22.5007 30.5723 22.0572C30.8405 21.6138 30.9811 21.1049 30.9787 20.5867V11.4147C30.9813 10.8964 30.8407 10.3875 30.5725 9.94401C30.3043 9.50054 29.9189 9.13972 29.4587 8.90134L21.2093 4.68534C20.8285 4.49081 20.407 4.38939 19.9793 4.38939C19.5517 4.38939 19.1302 4.49081 18.7493 4.68534V4.68534Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30.544 9.90001L19.9787 15.2973L9.41467 9.90001"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.9787 15.2973V27.612"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.4787 12.488V16.3707"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

Shipping2.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
