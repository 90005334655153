import React from 'react';

import PropTypes from 'prop-types';

export default function LocationPin({
  color = 'currentColor',
  size = '1em',
  ...rest
}) {
  return (
    <svg
      viewBox="0 0 36 32"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      height={size}
      width={size}
      {...rest}
    >
      <path
        d="M11 11C11 12.3261 11.5268 13.5979 12.4645 14.5355C13.4021 15.4732 14.6739 16 16 16C17.3261 16 18.5979 15.4732 19.5355 14.5355C20.4732 13.5979 21 12.3261 21 11C21 9.67392 20.4732 8.40215 19.5355 7.46447C18.5979 6.52678 17.3261 6 16 6C14.6739 6 13.4021 6.52678 12.4645 7.46447C11.5268 8.40215 11 9.67392 11 11Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 1C18.6522 1 21.1957 2.05357 23.0711 3.92893C24.9464 5.8043 26 8.34784 26 11C26 15.5427 19.2347 26.9333 16.8333 30.5533C16.7421 30.6909 16.6183 30.8037 16.4729 30.8817C16.3275 30.9597 16.165 31.0006 16 31.0006C15.835 31.0006 15.6725 30.9597 15.5271 30.8817C15.3817 30.8037 15.2579 30.6909 15.1667 30.5533C12.7653 26.9333 6 15.5427 6 11C6 8.34784 7.05357 5.8043 8.92893 3.92893C10.8043 2.05357 13.3478 1 16 1V1Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

LocationPin.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
