import React from 'react';

export default function HappyReturnsOffer() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.90013 7.48666L7.94679 8.44666C7.86253 8.52922 7.7956 8.62776 7.7499 8.73651C7.7042 8.84526 7.68066 8.96204 7.68066 9.08C7.68066 9.19796 7.7042 9.31473 7.7499 9.42349C7.7956 9.53224 7.86253 9.63077 7.94679 9.71333C8.07206 9.83997 8.2328 9.92562 8.40779 9.95895C8.58277 9.99228 8.76374 9.97172 8.92679 9.9L10.2601 9.32C10.4232 9.24827 10.6042 9.22771 10.7791 9.26104C10.9541 9.29437 11.1149 9.38002 11.2401 9.50666C11.3244 9.58922 11.3913 9.68776 11.437 9.79651C11.4827 9.90526 11.5063 10.022 11.5063 10.14C11.5063 10.258 11.4827 10.3747 11.437 10.4835C11.3913 10.5922 11.3244 10.6908 11.2401 10.7733L10.3201 11.7333"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.19318 8.19333L7.83984 7.84"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.3797 11.38L11.0264 11.0267"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.78702 5.07333L2.42702 7.66C2.42393 7.79272 2.44841 7.92464 2.4989 8.04742C2.54938 8.1702 2.62479 8.28118 2.72035 8.37334L9.05368 14.7067C9.14608 14.8002 9.25611 14.8745 9.37742 14.9251C9.49872 14.9758 9.62888 15.0019 9.76035 15.0019C9.89182 15.0019 10.022 14.9758 10.1433 14.9251C10.2646 14.8745 10.3746 14.8002 10.467 14.7067L14.707 10.4667C14.8005 10.3743 14.8748 10.2642 14.9255 10.1429C14.9762 10.0216 15.0023 9.89147 15.0023 9.76C15.0023 9.62853 14.9762 9.49838 14.9255 9.37707C14.8748 9.25577 14.8005 9.14573 14.707 9.05333L8.29368 2.66667C8.19754 2.57086 8.08301 2.49549 7.95699 2.44508C7.83096 2.39467 7.69605 2.37026 7.56035 2.37333L5.00035 2.74667"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.62695 6.62668C6.17924 6.62668 6.62695 6.17896 6.62695 5.62668C6.62695 5.07439 6.17924 4.62668 5.62695 4.62668C5.07467 4.62668 4.62695 5.07439 4.62695 5.62668C4.62695 6.17896 5.07467 6.62668 5.62695 6.62668Z"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.92 4.92L1 1"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

HappyReturnsOffer.propTypes = {};
