import React from 'react';

export default function HappyReturnsLeaf() {
  return (
    <svg
      width="16"
      height="14"
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.83335 13.0601C5.50197 13.0601 4.22511 12.5312 3.28368 11.5898C2.34225 10.6484 1.81335 9.37151 1.81335 8.04013C1.81335 0.760126 10.5734 4.50013 14.7934 1.00013C14.8415 0.970667 14.8969 0.955078 14.9534 0.955078C15.0098 0.955078 15.0652 0.970667 15.1134 1.00013C15.1651 1.02248 15.2104 1.05733 15.2453 1.10155C15.2802 1.14577 15.3036 1.19797 15.3134 1.25346C16.6 8.76013 11.0467 13.0601 6.83335 13.0601Z"
        stroke="#05944F"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.84 6.7002C6.75957 7.27296 4.81015 8.24427 3.1 9.5602L0.5 11.7802"
        stroke="#05944F"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

HappyReturnsLeaf.propTypes = {};
