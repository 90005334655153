import React from 'react';

export default function ScrubsThread() {
  return (
    <svg
      width="16"
      height="20"
      viewBox="0 0 16 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.4859 4.11401H1.80926C1.46639 4.11401 1.18066 4.39974 1.18066 4.74261C1.18066 5.08549 1.46639 5.37121 1.80926 5.37121H11.4859C11.8288 5.37121 12.1145 5.08549 12.1145 4.74261C12.1145 4.39974 11.8478 4.11401 11.4859 4.11401Z"
        fill="#333333"
      />
      <path
        d="M11.4859 6.22839H1.80926C1.46639 6.22839 1.18066 6.51412 1.18066 6.85699C1.18066 7.19987 1.46639 7.48559 1.80926 7.48559H11.4859C11.8288 7.48559 12.1145 7.19987 12.1145 6.85699C12.1145 6.49507 11.8478 6.22839 11.4859 6.22839Z"
        fill="#333333"
      />
      <path
        d="M11.4859 8.32373H1.80926C1.46639 8.32373 1.18066 8.60946 1.18066 8.95233C1.18066 9.2952 1.46639 9.58093 1.80926 9.58093H11.4859C11.8288 9.58093 12.1145 9.2952 12.1145 8.95233C12.1145 8.60946 11.8478 8.32373 11.4859 8.32373Z"
        fill="#333333"
      />
      <path
        d="M11.4859 10.4191H1.80926C1.46639 10.4191 1.18066 10.7048 1.18066 11.0477C1.18066 11.3905 1.46639 11.6763 1.80926 11.6763H11.4859C11.8288 11.6763 12.1145 11.3905 12.1145 11.0477C12.1145 10.7048 11.8478 10.4191 11.4859 10.4191Z"
        fill="#333333"
      />
      <path
        d="M15.5051 9.21898C15.1241 8.55228 14.8575 7.48556 15.7337 6.19027C15.9242 5.90454 15.848 5.50452 15.5623 5.31404C15.2765 5.12355 14.8765 5.19975 14.686 5.48548C13.3907 7.42842 13.9622 9.04754 14.4194 9.84758C14.6289 10.2285 14.7432 10.6476 14.7432 11.0476C14.7432 11.8667 14.0765 12.5144 13.2764 12.5144H1.80926C1.46639 12.5144 1.18066 12.8001 1.18066 13.143C1.18066 13.4858 1.46639 13.7716 1.80926 13.7716H13.2764C14.7813 13.7716 16.0004 12.5525 16.0004 11.0476C16.0004 10.419 15.8289 9.79043 15.5051 9.21898Z"
        fill="#333333"
      />
      <path
        d="M11.4859 15.886C11.8288 15.886 12.1145 15.6003 12.1145 15.2574C12.1145 14.9145 11.8288 14.6288 11.4859 14.6288H1.80926C1.46639 14.6288 1.18066 14.9145 1.18066 15.2574C1.18066 15.6003 1.46639 15.886 1.80926 15.886H11.4859Z"
        fill="#333333"
      />
      <path
        d="M1.066 3.0473C1.16124 3.18064 1.31363 3.27588 1.48507 3.27588H11.8093C11.9808 3.27588 12.1332 3.19968 12.2284 3.0473L13.238 1.61866C13.4285 1.33293 13.238 0.951965 12.8951 0.951965H0.418351C0.0754786 0.951965 -0.115006 1.33293 0.0754785 1.61866L1.066 3.0473Z"
        fill="#333333"
      />
      <path
        d="M12.2475 16.9527C12.1522 16.8194 11.9998 16.7241 11.8284 16.7241H1.48507C1.31363 16.7241 1.16124 16.8003 1.066 16.9527L0.0754785 18.3813C-0.115006 18.6671 0.0754786 19.048 0.418351 19.048H12.8761C13.2189 19.048 13.4094 18.6671 13.2189 18.3813L12.2475 16.9527Z"
        fill="#333333"
      />
    </svg>
  );
}

ScrubsThread.propTypes = {};
