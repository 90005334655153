import React from 'react';

import PropTypes from 'prop-types';

export default function Top({
  color = 'currentColor',
  width = '17',
  height = '26',
  ...rest
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 17 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.5 24.2349C1.5 24.2349 4.71253 24.9856 8.26156 24.9856C11.8106 24.9856 15.569 24.2349 15.569 24.2349C15.569 24.2349 13.5953 20.8795 13.5953 17.2075C13.5953 15.3443 14.5163 13.5493 15.2785 11.681C15.5178 11.0946 15.569 9.85026 15.569 9.85026C15.569 9.85026 15.4256 8.12413 14.5104 6.62381C14.5104 4.73064 14.5104 0.985558 14.5104 0.985558H13.0987V5.12349C13.0987 5.12349 12.6246 8.72951 8.53448 8.72951C4.44438 8.72951 3.90802 5.12349 3.90802 5.12349V0.985558H2.60914V6.62381C2.60914 6.62381 1.5 8.46301 1.5 9.85026C1.5 11.2376 2.0706 11.681 2.0706 11.681C2.0706 11.681 3.42959 14.9948 3.42959 17.2075C3.42959 19.4201 1.5 24.2349 1.5 24.2349Z"
        stroke={color}
      />
    </svg>
  );
}

Top.propTypes = {
  color: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
