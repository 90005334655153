import React from 'react';

import PropTypes from 'prop-types';

export default function CaretRight({
  color = 'currentColor',
  size = '1em',
  strokeWidth = '1.5',
  ...rest
}) {
  return (
    <svg
      viewBox="0 0 36 32"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      height={size}
      width={size}
      {...rest}
    >
      <path
        d="M7.33334 1L21.6267 15.2933C21.7196 15.3861 21.7932 15.4962 21.8435 15.6175C21.8938 15.7388 21.9197 15.8687 21.9197 16C21.9197 16.1313 21.8938 16.2612 21.8435 16.3825C21.7932 16.5038 21.7196 16.6139 21.6267 16.7067L7.33334 31"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

CaretRight.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  strokeWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
