import React from 'react';

import PropTypes from 'prop-types';

export default function Tag1({
  color = 'currentColor',
  size = '1em',
  ...rest
}) {
  return (
    <svg
      viewBox="0 0 36 32"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      height={size}
      width={size}
      {...rest}
    >
      <path
        d="M19.055 15.8247L16.8762 18.0187C16.6837 18.2073 16.5307 18.4325 16.4262 18.6811C16.3218 18.9296 16.268 19.1965 16.268 19.4661C16.268 19.7357 16.3218 20.0025 16.4262 20.2511C16.5307 20.4996 16.6837 20.7248 16.8762 20.9135C17.1625 21.2029 17.5299 21.3987 17.9298 21.4748C18.3297 21.551 18.7433 21.504 19.1159 21.3401L22.1631 20.0146C22.5357 19.8506 22.9494 19.8037 23.3493 19.8798C23.7492 19.956 24.1165 20.1517 24.4028 20.4412C24.5954 20.6299 24.7484 20.8551 24.8528 21.1036C24.9572 21.3521 25.011 21.619 25.011 21.8886C25.011 22.1582 24.9572 22.4251 24.8528 22.6736C24.7484 22.9221 24.5954 23.1473 24.4028 23.336L22.3002 25.53"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.4393 17.4397L16.6318 16.6322"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.7225 24.7225L23.915 23.915"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.08332 10.3092L4.26057 16.2208C4.25352 16.5241 4.30945 16.8256 4.42484 17.1062C4.54023 17.3868 4.71257 17.6404 4.93096 17.851L19.4052 32.3252C19.6163 32.539 19.8678 32.7087 20.145 32.8245C20.4223 32.9403 20.7197 33 21.0202 33C21.3206 33 21.6181 32.9403 21.8953 32.8245C22.1726 32.7087 22.4241 32.539 22.6352 32.3252L32.3253 22.6351C32.5391 22.424 32.7088 22.1725 32.8246 21.8953C32.9404 21.618 33.0001 21.3206 33.0001 21.0201C33.0001 20.7197 32.9404 20.4222 32.8246 20.145C32.7088 19.8677 32.5391 19.6163 32.3253 19.4051L17.6683 4.80899C17.4485 4.59003 17.1868 4.41777 16.8988 4.30257C16.6108 4.18736 16.3024 4.13159 15.9923 4.1386L10.1417 4.99182"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.5737 13.8592C12.8359 13.8592 13.8591 12.836 13.8591 11.5738C13.8591 10.3116 12.8359 9.2884 11.5737 9.2884C10.3115 9.2884 9.2883 10.3116 9.2883 11.5738C9.2883 12.836 10.3115 13.8592 11.5737 13.8592Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.95878 9.95878L1 1"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

Tag1.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
