import React from 'react';

import PropTypes from 'prop-types';

export default function EmptyStar({
  color = 'currentColor',
  size = '1em',
  ...rest
}) {
  return (
    <svg
      viewBox="0 0 36 32"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      height={size}
      width={size}
      {...rest}
    >
      <g clipPath="url(#clip0_1576_7529)">
        <path
          d="M33.0063 10.7188L23.875 9.38752L19.7938 1.11251C19.0625 -0.362494 16.9438 -0.381244 16.2063 1.11251L12.125 9.38752L2.9938 10.7188C1.35629 10.9563 0.700045 12.975 1.88754 14.1313L8.49379 20.5688L6.93129 29.6625C6.65004 31.3063 8.38129 32.5375 9.8313 31.7688L18 27.475L26.1688 31.7688C27.6188 32.5313 29.35 31.3063 29.0688 29.6625L27.5063 20.5688L34.1125 14.1313C35.3 12.975 34.6438 10.9563 33.0063 10.7188ZM25.3625 19.8688L27.1 29.9938L18 25.2188L8.90629 30L10.6438 19.875L3.28129 12.6938L13.45 11.2188L18 2.00001L22.55 11.2188L32.7188 12.6938L25.3625 19.8688Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_1576_7529">
          <rect width="36" height="32" fill="transparent" />
        </clipPath>
      </defs>
    </svg>
  );
}

EmptyStar.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
