import React from 'react';

import PropTypes from 'prop-types';

export default function Tag2({
  color = 'currentColor',
  size = '1em',
  ...rest
}) {
  return (
    <svg
      viewBox="0 0 36 32"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      height={size}
      width={size}
      {...rest}
    >
      <path
        d="M1.41467 3.41333V11.756C1.41454 12.8166 1.83562 13.8338 2.58533 14.584L18 30C18.3751 30.3749 18.8837 30.5856 19.414 30.5856C19.9443 30.5856 20.4529 30.3749 20.828 30L30 20.8267C30.3749 20.4516 30.5856 19.943 30.5856 19.4127C30.5856 18.8823 30.3749 18.3737 30 17.9987L14.5853 2.584C13.8351 1.83429 12.8179 1.41321 11.7573 1.41333H3.41467C2.88423 1.41333 2.37552 1.62405 2.00045 1.99912C1.62538 2.37419 1.41467 2.8829 1.41467 3.41333V3.41333Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.41467 8.41333C6.41467 8.67598 6.4664 8.93605 6.56691 9.1787C6.66742 9.42135 6.81473 9.64183 7.00045 9.82755C7.18617 10.0133 7.40665 10.1606 7.6493 10.2611C7.89195 10.3616 8.15202 10.4133 8.41467 10.4133C8.67731 10.4133 8.93738 10.3616 9.18003 10.2611C9.42268 10.1606 9.64316 10.0133 9.82888 9.82755C10.0146 9.64183 10.1619 9.42135 10.2624 9.1787C10.3629 8.93605 10.4147 8.67598 10.4147 8.41333C10.4147 8.15069 10.3629 7.89062 10.2624 7.64797C10.1619 7.40532 10.0146 7.18484 9.82888 6.99912C9.64316 6.8134 9.42268 6.66608 9.18003 6.56557C8.93738 6.46507 8.67731 6.41333 8.41467 6.41333C8.15202 6.41333 7.89195 6.46507 7.6493 6.56557C7.40665 6.66608 7.18617 6.8134 7.00045 6.99912C6.81473 7.18484 6.66742 7.40532 6.56691 7.64797C6.4664 7.89062 6.41467 8.15069 6.41467 8.41333Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

Tag2.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
