import React from 'react';

import PropTypes from 'prop-types';

export default function ViewOff({
  color = 'currentColor',
  size = '1em',
  ...rest
}) {
  return (
    <svg
      viewBox="0 0 36 32"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      height={size}
      width={size}
      {...rest}
    >
      <path
        d="M3.70665 28L28.7067 4"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 25.4C13.2946 25.8041 14.6438 26.0065 16 26C21.4667 26.0933 27.0133 22.2533 30.4267 18.48C30.7949 18.075 30.9989 17.5473 30.9989 17C30.9989 16.4527 30.7949 15.925 30.4267 15.52C29.1924 14.1558 27.8207 12.9226 26.3333 11.84"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.7867 9.69333C20.6884 8.60478 18.3637 8.02473 16 8C10.6667 7.90667 5.06666 11.6667 1.57332 15.52C1.20511 15.925 1.00108 16.4527 1.00108 17C1.00108 17.5473 1.20511 18.075 1.57332 18.48C3.4665 20.5964 5.68556 22.3969 8.14666 23.8133"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 20.0933C11.2767 19.0899 10.94 17.859 11.052 16.6271C11.1639 15.3952 11.7169 14.2452 12.6093 13.3885C13.5016 12.5319 14.6732 12.0263 15.9087 11.9647C17.1441 11.9031 18.3602 12.2897 19.3333 13.0533"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21 17C21 17.6566 20.8707 18.3068 20.6194 18.9134C20.3681 19.52 19.9998 20.0712 19.5355 20.5355C19.0712 20.9998 18.52 21.3681 17.9134 21.6194C17.3068 21.8707 16.6566 22 16 22"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

ViewOff.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
