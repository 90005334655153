import * as React from 'react';

import PropTypes from 'prop-types';

const MensBottomsIcon = (props) => (
  <svg
    width={23}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g
      clipPath="url(#a)"
      stroke="#000"
      strokeWidth={1.375}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M5.75 8.604V22.26a.718.718 0 0 0 .719.719H16.53a.718.718 0 0 0 .719-.719V8.604" />
      <path d="M17.25 12.916h3.594a.718.718 0 0 0 .718-.718V7.166a5.75 5.75 0 0 0-5.75-5.75h-1.437a2.875 2.875 0 1 1-5.75 0H7.187a5.75 5.75 0 0 0-5.75 5.75v5.032a.718.718 0 0 0 .72.718H5.75" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" transform="translate(0 .698)" d="M0 0h23v23H0z" />
      </clipPath>
    </defs>
  </svg>
);

MensBottomsIcon.propTypes = {
  color: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default MensBottomsIcon;
