import React from 'react';

import PropTypes from 'prop-types';

export default function Home({
  color = 'currentColor',
  size = '1em',
  ...rest
}) {
  return (
    <svg
      viewBox="0 0 28 32"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      height={size}
      width={size}
      {...rest}
    >
      <path
        d="M5.74489 0.591634C5.90591 0.231706 6.2634 0 6.65771 0H20.9787C21.373 0 21.7305 0.231706 21.8915 0.591634L26.1818 10.1818H1.45454L5.74489 0.591634Z"
        fill={color}
      />
      <ellipse
        cx="5.09091"
        cy="10.9091"
        rx="3.63636"
        ry="3.63636"
        fill={color}
      />
      <ellipse
        cx="22.5455"
        cy="10.9091"
        rx="3.63636"
        ry="3.63636"
        fill={color}
      />
      <ellipse
        cx="16.7273"
        cy="10.9091"
        rx="3.63636"
        ry="3.63636"
        fill={color}
      />
      <ellipse
        cx="10.9091"
        cy="10.9091"
        rx="3.63636"
        ry="3.63636"
        fill={color}
      />
      <line
        x1="13.5455"
        y1="25.7273"
        x2="1"
        y2="25.7273"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.0909 24.7273H14.3909C14.4856 24.7273 14.5722 24.7808 14.6146 24.8655L15.8191 27.2746C15.9022 27.4408 15.7813 27.6364 15.5955 27.6364H13.0909V24.7273Z"
        fill={color}
      />
      <path
        d="M2.90909 16.25C2.90909 16.1119 3.02102 16 3.15909 16H5.56818C5.70625 16 5.81818 16.1119 5.81818 16.25V26.1818H2.90909V16.25Z"
        fill={color}
      />
      <path
        d="M5.74489 0.591634C5.90591 0.231706 6.2634 0 6.65771 0H20.9787C21.373 0 21.7305 0.231706 21.8915 0.591634L26.1818 10.1818H1.45454L5.74489 0.591634Z"
        fill={color}
      />
      <ellipse
        cx="5.09091"
        cy="10.9091"
        rx="3.63636"
        ry="3.63636"
        fill={color}
      />
      <ellipse
        cx="22.5455"
        cy="10.9091"
        rx="3.63636"
        ry="3.63636"
        fill={color}
      />
      <ellipse
        cx="16.7273"
        cy="10.9091"
        rx="3.63636"
        ry="3.63636"
        fill={color}
      />
      <ellipse
        cx="10.9091"
        cy="10.9091"
        rx="3.63636"
        ry="3.63636"
        fill={color}
      />
      <line
        x1="13.5455"
        y1="25.7273"
        x2="1"
        y2="25.7273"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.0909 24.7273H14.3909C14.4856 24.7273 14.5722 24.7808 14.6146 24.8655L15.8191 27.2746C15.9022 27.4408 15.7813 27.6364 15.5955 27.6364H13.0909V24.7273Z"
        fill={color}
      />
      <path
        d="M2.90909 16.25C2.90909 16.1119 3.02102 16 3.15909 16H5.56818C5.70625 16 5.81818 16.1119 5.81818 16.25V26.1818H2.90909V16.25Z"
        fill={color}
      />
      <path
        d="M21.8182 16C18.6016 16 16 18.5722 16 21.7524C16 25.1792 19.6738 29.9045 21.1865 31.7042C21.519 32.0986 22.1257 32.0986 22.4582 31.7042C23.9626 29.9045 27.6364 25.1792 27.6364 21.7524C27.6364 18.5722 25.0348 16 21.8182 16ZM21.8182 23.8069C21.2671 23.8069 20.7386 23.5904 20.3489 23.2051C19.9592 22.8199 19.7403 22.2973 19.7403 21.7524C19.7403 21.2076 19.9592 20.685 20.3489 20.2997C20.7386 19.9144 21.2671 19.698 21.8182 19.698C22.3693 19.698 22.8978 19.9144 23.2875 20.2997C23.6772 20.685 23.8961 21.2076 23.8961 21.7524C23.8961 22.2973 23.6772 22.8199 23.2875 23.2051C22.8978 23.5904 22.3693 23.8069 21.8182 23.8069V23.8069Z"
        fill={color}
      />
    </svg>
  );
}

Home.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
