import React from 'react';

import PropTypes from 'prop-types';

export default function CaretLeft({
  color = 'currentColor',
  size = '1em',
  ...rest
}) {
  return (
    <svg
      viewBox="0 0 36 32"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      height={size}
      width={size}
      {...rest}
    >
      <path
        d="M21.6667 31L7.37333 16.7067C7.28044 16.6139 7.20675 16.5038 7.15648 16.3825C7.1062 16.2612 7.08032 16.1313 7.08032 16C7.08032 15.8687 7.1062 15.7388 7.15648 15.6175C7.20675 15.4962 7.28044 15.3861 7.37333 15.2933L21.6667 1"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

CaretLeft.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
