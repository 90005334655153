import React from 'react';

import PropTypes from 'prop-types';

export default function AlertCircle({
  color = 'currentColor',
  size = '1em',
  ...rest
}) {
  return (
    <svg
      viewBox="0 0 36 32"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      height={size}
      width={size}
      {...rest}
    >
      <path
        d="M16 22C15.9011 22 15.8044 22.0293 15.7222 22.0843C15.64 22.1392 15.5759 22.2173 15.5381 22.3087C15.5002 22.4 15.4903 22.5006 15.5096 22.5975C15.5289 22.6945 15.5765 22.7836 15.6464 22.8536C15.7164 22.9235 15.8055 22.9711 15.9025 22.9904C15.9994 23.0097 16.1 22.9998 16.1913 22.9619C16.2827 22.9241 16.3608 22.86 16.4157 22.7778C16.4707 22.6956 16.5 22.5989 16.5 22.5C16.5 22.3674 16.4473 22.2402 16.3536 22.1464C16.2598 22.0527 16.1326 22 16 22Z"
        fill={color}
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 18V7"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M1 16C1 19.9782 2.58035 23.7936 5.3934 26.6066C8.20644 29.4196 12.0218 31 16 31C19.9782 31 23.7936 29.4196 26.6066 26.6066C29.4196 23.7936 31 19.9782 31 16C31 12.0218 29.4196 8.20644 26.6066 5.3934C23.7936 2.58035 19.9782 1 16 1C12.0218 1 8.20644 2.58035 5.3934 5.3934C2.58035 8.20644 1 12.0218 1 16V16Z"
        stroke={color}
        strokeWidth="1.5"
      />
    </svg>
  );
}

AlertCircle.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
