import * as React from 'react';

import PropTypes from 'prop-types';

const MensTopsIcon = (props) => (
  <svg
    width={23}
    height={25}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g
      clipPath="url(#a)"
      stroke="#000"
      strokeWidth={1.375}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M3.833 1.066h15.334c.254 0 .498.107.677.298.18.19.281.449.281.718v2.033H2.875V2.082c0-.27.101-.528.28-.718a.931.931 0 0 1 .678-.298ZM20.125 8.69a4.192 4.192 0 0 1-3.05-1.34 4.718 4.718 0 0 1-1.262-3.235M2.875 8.69c1.144 0 2.24-.483 3.05-1.34a4.718 4.718 0 0 0 1.263-3.235" />
      <path d="M9.884 11.726c.051-.416.243-.799.54-1.076.298-.277.68-.43 1.076-.43s.778.152 1.076.43c.297.277.489.66.54 1.076l.705 10.918c.053.36.224.686.484.923s.59.367.933.368h3.45c.38 0 .746-.16 1.016-.446.27-.286.421-.674.421-1.078V4.115H2.875v18.296c0 .404.151.792.421 1.078.27.286.635.446 1.017.446h3.45c.343 0 .675-.13.935-.366a1.55 1.55 0 0 0 .486-.924l.7-10.919Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" transform="translate(0 .304)" d="M0 0h23v24.394H0z" />
      </clipPath>
    </defs>
  </svg>
);

MensTopsIcon.propTypes = {
  color: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default MensTopsIcon;
